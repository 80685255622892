<template>
  <div class="custom-control custom-radio">
    <input
      type="radio"
      class="custom-control-input"
      v-bind:name="name"
      v-bind:id="componentId"
      v-bind:checked="checked"
      v-on:change="setChecked"
    />
    <label class="custom-control-label" v-bind:for="componentId">{{
      label
    }}</label>
  </div>
</template>
<script>
  export default {
    name: "WeRadioButton",
    props: {
      checked: {
        default: false,
      },
      label: {
        default: null,
      },
      name: {
        default: null,
      },
      value: {
        default: null,
      },
    },
    methods: {
      setChecked() {
        this.$emit("on-checked", this.value);
      },
    },
    computed: {
      componentId() {
        return "we-radio-" + helper.getRandomInteger(1, 999999);
      },
    },
  };
</script>